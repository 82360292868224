<template>
  <div>
    <v-breadcrumbs
      :items="breadcrumbs"
      class="grey lighten-2 white--text"
    ></v-breadcrumbs>
    <v-progress-circular
      v-if="loading"
      :size="100"
      :width="4"
      color="fmq_black"
      indeterminate
      class="d-flex mx-auto py-8 mt-8"
    ></v-progress-circular>
    <div v-else>
      <div class="pa-4">
        <div class="d-flex justify-space-between align-center mb-4">
          <v-btn small depressed color="fmq_gray" dark outlined to="/produtos">
            <v-icon small> mdi-arrow-left </v-icon>Voltar</v-btn
          >
        </div>
        <ProdutoFormulario
          @send="editar"
          :produto="produto"
          :listLinhas="listLinhas"
          :listMedicoAprovadores="listMedicoAprovadores"
          :listResponsavel="listResponsavel"
          :listEmpresasParceiras="listEmpresasParceiras"
          :loadingBtn="loadingBtn"
        />
      </div>
    </div>
    <AlertSuccess
      :dialog="success"
      dialogMessage="Produto editado com sucesso"
      @close="goToProdutos"
    />
    <AlertError :alertError="error" :messageError="messageError" />
  </div>
</template>

<script>
import { editarProduto, exibirProduto } from "@/services/produtos.js";
import { listarLinha } from "@/services/linhas";
import { listarMedicoAprovadores, listFuncionarios } from "@/services/user";
import { listarEmpresaParceira } from "@/services/empresas-parceiras";
import ProdutoFormulario from "@/components/produtos/ProdutoFormulario.vue";
export default {
  name: "ProdutoEditar",
  components: { ProdutoFormulario },
  data: () => ({
    breadcrumbs: [
      {
        text: "Produtos",
        disabled: false,
        to: "/produtos",
      },
      {
        text: "Editar Produto",
        disabled: true,
        to: "",
      },
    ],
    produto: {},
    loading: false,
    error: false,
    success: false,
    loadingBtn: false,
    messageError: null,
    listLinhas: [],
    listMedicoAprovadores: [],
    listResponsavel: [],
    listEmpresasParceiras: [],
  }),
    beforeCreate() {
    if(this.$store.state.user.data.tipo === "Representante") {
      this.$router.push({path: '/'})
    }
  },
  created() {
    this.exibir();
    this.getLinhas();
    this.getMedicoAprovadores();
    this.getResponsavel();
    this.getEmpresasParceiras();
  },
  methods: {
    async editar(event) {
      this.error = false;
      this.sucess = false;
      this.loadingBtn = true;
      try {
        await editarProduto(this.$route.params.id, event).then(() => {
          this.success = true;
        });
        this.loadingBtn = false;
      } catch (e) {
        this.loadingBtn = false;
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    async exibir() {
      this.error = false;
      this.loading = true;
      try {
        const resp = await exibirProduto(this.$route.params.id);
        this.produto = resp.data;
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    goToProdutos() {
      this.sucess = false;
      this.$router.push({ name: "Produtos" });
    },
    async getLinhas() {
      const resp = await listarLinha();
      this.listLinhas = resp.data;
    },
    async getMedicoAprovadores() {
      const resp = await listarMedicoAprovadores();
      this.listMedicoAprovadores = resp.data;
    },
    async getResponsavel() {
      const resp = await listFuncionarios({ mesaId: 2 });
      this.listResponsavel = resp.data;
    },
    async getEmpresasParceiras() {
      const resp = await listarEmpresaParceira();
      this.listEmpresasParceiras = resp.data;
    },
  },
};
</script>

<style></style>
